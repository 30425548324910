.customers-section {
	margin-top: 50px;

	header {
		text-align: center;
		h2 {
			font-size: 3em;
			color: #3d3d3d;
		}
	}

	.logos-container {
		margin-top: 40px;
		position: relative; // Necesario para posicionar los pseudo-elementos de forma absoluta

    // Pseudo-elementos para los degradados en los bordes
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      width: 100px; // Ajusta este valor según el tamaño del efecto deseado
      height: 100%;
      pointer-events: none;
      z-index: 2;
    }
    &:before {
      left: 0;
      background: linear-gradient(to right, white, transparent);
    }
    &:after {
      right: 0;
      background: linear-gradient(to left, white, transparent);
    }
		.marquee {
			--gap: 30px;
			position: relative;
			overflow: hidden;
			padding: 10px 0;
			white-space: nowrap;
			display: flex;
			flex-direction: row;
			gap: var(--gap);
			margin-bottom: 10px;

			&__inner {
				display: flex;
				flex-shrink: 0;
				animation: marquee 30s linear infinite;
				justify-content: space-between;
				gap: var(--gap);
				align-items: center;
			}

			&.reverse {
				.marquee__inner {
					animation-direction: reverse;
				}
			}
		}

		img {
			height: 100px;
			width: auto;
			filter: grayscale(1);
			background-color: white;
			padding: 20px;
			border-radius: 10px;
		}


	}
}

@media screen and (max-width: 768px) {
	.customers-section {
		.logos-container {

			&::before, &::after{
				width: 30px
			}
			img {
				height: 75px;
				width: auto;
				padding: 10px 5px;
			}
		}
	}
}
@keyframes marquee {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-100% - var(--gap)));
	}
}
