.pricing {
	text-align: center;
	h2 {
		font-size: 3em;
	}

	.check-pricing{
		margin-top: 40px;
		color: gray;
		padding: 0px 10%;
		a{
			color: #58D39A;
		}
	}

	.price-cards-container {
		display: flex;
		justify-content: center;
		margin-top: 30px;
		gap: 50px;
		.price-card {
			text-align: start;
			max-width: 250px;
			border: 1px solid lightgrey;
			padding: 20px;
			border-radius: 10px;
			header {
				padding-bottom: 10px;
				border-bottom: 1px solid #e5e5e5;
				h5 {
					color: rgb(74, 74, 74);
					font-size: 1.5em;
				}
			}
			.price-container {
				padding-top: 10px;
				padding-bottom: 10px;
				border-bottom: 1px solid #e5e5e5;
				color: gray;
				span {
					font-size: 1.7em;
					// background-color: #fff8d3;
					// color: #caa40d;
					padding: 0px 8px;
					border-radius: 5px;
				}
				.green{
					background-color: #daffee;
					color: #0f874f;
				}
			}
			.perks {
				padding-top: 10px;
				ul {
					list-style-type: none;
					display: flex;
					flex-direction: column;
					gap: 10px;
					li {
						display: flex;
						align-items: flex-start;
						gap: 10px;

						.transparent{
							opacity: 0;
						}
					}
				}
			}
		}

		@media (max-width: 768px) {
			flex-direction: column;
			align-items: center;
		}
	}

	@media (max-width: 768px) {
		h2 {
			font-size: 2.5em;
		}
	}
}
